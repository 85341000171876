




















































import {Component, Ref, Vue} from "vue-property-decorator";
import ProductCategory from "@/models/ProductCategory";
import FilePickerComponent from "@/components/FilePickerComponent.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductCategoryService from "@/services/ProductCategoryService";
import DatePickerComponent from "@/components/DatePickerComponent.vue";
import LangModule from "@/store/LangModule";

@Component({
    components:{DatePickerComponent, FilePickerComponent},
    filters: {filterId(list: ProductCategory[], productCategoryId: number) { return list.filter(pc => pc.id != productCategoryId) }}
})
export default class ProductCategoryView extends Vue {
    lang: any = getModule(LangModule).lang
    @Ref() readonly form!: HTMLFormElement
    productCategories: ProductCategory[] = []
    productCategory: ProductCategory = new ProductCategory()
    loading: boolean = false
    image: File | null = null
    referenceRule = [(v: any) => v ? true : this.lang.referenceRequired]

    created() {
        this.refresh()
    }

    refresh() {
        ProductCategoryService.getProductCategory(this, Number(this.$route.params.productCategoryId))
        ProductCategoryService.getProductCategories(this, this.productCategories)
    }

    deleteProductCategory() {
        getModule(DialogModule).showDialog(new Dialog(
            this.lang.warning,
            this.lang.productCategoryDeleteQuestion,
            () => ProductCategoryService.deleteProductCategory(this, this.productCategory.id!)
        ))
    }

    updateProductCategory() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                this.lang.warning, this.lang.productCategoryUpdateQuestion,
                () => ProductCategoryService.patchProductCategory(this, this.productCategory, this.image!)
            ))
        }
    }
}
